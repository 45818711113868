@import "font-familiies";
@import "tokens";

::-ms-clear { display:none; }

html {

    &.blur-open {
        .blur-container,
        .site-header,
        .site-footer {
            //filter: blur($blur);
        }
    }
    &.nav-blur {
        .blur-container,
        .site-footer {
            //filter: blur($blur);
        }
    }

    &.nav-blur,
    &.blur-open,
    &.modal-open,
    &.mobile-modal-open,
    &.inspiration-page-open {
        overflow: hidden;
        //height: 100%;
        // Mobile scroll fix?
        //body {
        //    overflow: hidden;
        //    height: 100%;
        //}

        .search-result-card-view {
            @media (max-width: $bp-two-cards) {
                display: none;
            }
        }
    }
}

body {
    background-color: $bg-base;
    color: $color-text;
    font-family: $paragraph-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .blur-container,
    .site-header,
    .site-footer {
        transition: $transition-default;
    }

    ::selection {
        background-color: $accessibilityAccent;
        color: $color-text-dark-bg;
    }

    /* For dev to check margins 
    &:after {
        content: "";
        position: fixed;
        top: 0;
        bottom: 0;
        background-color: $vip-sunrise;
        z-index: $modal;
        left: $mobile-side-margin;
        right: $mobile-side-margin;
        opacity: 0.2;

        @media (min-width: $bp-content-max-width) {
            width: $bp-content-max-width - 1.25;
            margin: 0 (-$bp-content-max-width/2)+0.63;
            left: 50%;
            right: auto;
        }
    }
    &:before {
        @media (min-width: $bp-content-max-width) {
            content: "";
            bottom: 0;
            background-color: $active-orange;
            opacity: 0.2;
            left: 50%;
            margin: 0 -350px;
            position: fixed;
            top: 0;
            width: 700px;
            z-index: $modal;
        }
    }*/
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input[role="combobox"],
input[type="search"],
select:focus,
textarea {
    font-family: $paragraph-font-family;
    font-size: $paragraph-size-default !important;

    &:-webkit-autofill {
        -webkit-transition-delay: 99999s;
    }
}

::-ms-reveal,
input::-ms-reveal,
input ::-ms-reveal {
    color: transparent !important;
    display: none !important;
    font-size: 0 !important;
    height: 0 !important;
    opacity: 0 !important;
    visibility: hidden !important;
    width: 0 !important;
}

//button:focus,
input:focus,
textarea:focus,
.search-field__input-wrapper:focus,
svg:focus,
.search-field__wrapper:focus {
    //outline: $outline-style !important;
    //outline: $outline-style;
    outline: none !important;
}

ul, ol {
    letter-spacing: $default-letter-spacing;
}
ul {
    padding-left: 18px;
}

strong {
    letter-spacing: $medium-letter-spacing;
}

.table-wrapper {
    overflow-x: auto;
}

.no-phone,
.no-phone a{
    color: inherit !important;
    text-decoration: none;
    pointer-events: none !important;
}

.dark-wrapper {
    background-color: $bg-dark;
    padding: $standard-grid-gap-mobile;

    @media (min-width: $bp-two-cards) {
        padding: $standard-grid-gap-desktop 0;
    }
}

#app {
    overflow: hidden;
}

.block-margin {
    margin: 0 0 30px;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 15px;
}

input, textarea {
    border: none !important;
}

.fullscreen-page {
    height: 100%;
    body {
        height: 100%;
    }

    #app {
        overflow: hidden;
        
        & > div:first-child {
            height: 100%;
        }

        .blur-container {
            height: 100%;
        }



    }

    .page-container {
        display: grid;
        grid-template-rows: 110px auto;
        height: 100%;

        @media (min-width: $bp-two-cards) {
            grid-template-rows: 80px 60px auto;
        }
    }
}

.feather {
    width: 24px;
    height: 24px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}

.fade-text {
    transition: $transition-default;
    opacity: 0;

    height: 0;

    &--show {
        opacity: 1;
        visibility: visible;
        height: auto;
    }
}

.image-center-container {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background-color: $bg-base;
    position: relative;

    .inspiration-block & {
        height: 100%;
        position: absolute;
        left: 0;

    }

    .residence-block & {
        height: $residence-card-image-height-mobile;

        @media (min-width: $bp-two-cards) {
            height: $residence-card-image-height-desktop;
        }

        img {
            min-height: $residence-card-image-height-mobile;

            @media (min-width: $bp-two-cards) {
                min-height: $residence-card-image-height-desktop;
            }

        }

    }


    .fullscreen-page & {
        height: 190px;
    }

    img {
        align-self: center;
        display: block;
        max-width: none;
        width: auto;
        //max-width: 100%;
        //max-height: 50%;
    }
}

img {
    max-width: 100%;
}

#main-page {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: [header] $menu-height-mobile [content] auto [footer-start] 735px [footer-end];
    min-height: 100vh;
    width: 100%;

    @media (min-width: $bp-two-cards) {
        grid-template-rows: [header] $menu-height-tablet [content] auto [footer-start] 810px [footer-end];
    }

    @media (min-width: $bp-three-cards) {
        grid-template-rows: [header] $menu-height-desktop [content] auto [footer-start] 810px [footer-end];
    }

    @supports (-ms-ime-align: auto) {
        overflow: hidden;
    }

    .fullscreen-page & {
        grid-template-rows: [header] $menu-height-mobile-map [content] auto;
        //height: 100vh;
        height: 100%;
        min-height: auto;
        
        @media (min-width: $bp-three-cards) {
            grid-template-rows: [header] $menu-height-desktop-map [content] auto;
            height: 100%;
            //height: 100vh;
        }
    }

    .inspiration-articles {
        background-color: $bg-light;
        padding-bottom: 100px;
    }
}

@mixin spin {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.noselect {
    -webkit-tap-highlight-color: rgba(0,0,0,0);

}

.no-text-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

// Image strech for 27" screens
@media (min-width: 1200px) {
    .inspiration-page__image,
    .image-header__img-wrapper,
    .inspiration-block__img,
    .image-gallery--one-image {
        img {
            height: auto;
            width: 100%;
            max-width: none;
        }
    }
}
