@import 'variables';

/*  concepts    */
$concept-bg-original:           $light-grey;
$concept-bg-smarta:             $pale-peach;
$concept-bg-premium:            $premiumgrey;
$concept-text-original:         $premiumgrey;
$concept-text-smarta:           $premiumgrey;
$concept-text-premium:          $pale-peach;
$concept-highlight-original:    $brick-orange;
$concept-highlight-smarta:      $brick-orange;
$concept-highlight-premium:     $brick-orange;

/*  accent colors   */
$primaryAccent:         $brick-orange;
$accessibilityAccent:   $active-orange;

/* status colors */
$status-planned:    $off-white-two;
$status-for-sale:   $yay;
$status-vip-sale:   #daa63e;
$status-booked:     $faded-orange;
$status-reserved:   $french-blue;
$status-sold:       $oh-no;

/*  text colors */
$color-link:            $premiumgrey;
$color-link-hover:      $active-orange;
$color-text:            $premiumgrey;
$color-text-dark-bg:    $snow;
$color-text-light:      $medium-grey;
$color-form-help:       $medium-grey;
$color-error:           #a50000;

/*  font    */
/*      -size   */
$h1-size-mob:   2.5rem; //40px
$h1-size-desk:  3.13rem; //50px
$h2-size-mob:   1.88rem; //30px
$h2-size-desk:  2.5rem; //40px
$h3-size-mob:   1.56rem; //25px
$h3-size-desk:  1.88rem; //30px
$h4-size-mob:   1.25rem; //20px
$h4-size-desk:  1.25rem; //20px

$paragraph-size-large:      1.25rem;//20px
$paragraph-size-default:    1rem; // 16px
$paragraph-size-small:      0.88rem; // 14px
$paragraph-size-fineprint:  0.75rem; // 12px
/*      -family */
$header-font-family:        JMSerif, serif;
$paragraph-font-family:     'GillSansJM', sans-serif;
/*      -weight */
$headline-regular-weight:   400;
$headline-medium-weight:    700;
/*      -letter-spacing    */
$default-letter-spacing:    0.02em;
$medium-letter-spacing:     0.06em;
$large-letter-spacing:      0.09em;
$fineprint-letter-spacing:  0.02em;
/*      -line-height    */
$small-line-height:     1em;
$default-line-height:   1.2em;
$large-line-height:     1.5em;

$paragraph-margin-small:    0.7em;
$paragraph-margin-default:  1.4em;
$headline-margin-default:   0.15em;
$headline-margin-large:     0.63em;

/*  background colors   */
$bg-light:      $snow;
$bg-base:       $sunny-beach;
$bg-mid:        $sand;
$bg-dark:       $premiumgrey;
$bg-emergency:  #fbf1ee;

$bg-overlay:        rgba($snow, 0.95);
$bg-overlay-sheer:  rgba($snow, 0.85);
$bg-lighter:        rgba($snow, 0.4);
$bg-shade:          rgba($premiumgrey, 0.2);
$bg-darker:         rgba($bw-000, 0.4);

$highlight-bg:              rgba($apricot, .5);
$table-hover-bg:            rgba($yay, 0.1);
$vip-gradient:              linear-gradient(to right, rgba(218,166,62,1) 0%,rgba(239,210,114,0.8) 100%);
$vip-gradient-large:        radial-gradient(circle at 50% 50%, #daa63e, rgba(239, 210, 114, 0.8));
$booking-gradient:          radial-gradient(circle at 54% 50%, $yay, #AED7CC);
$startpage-search-gradient: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.35) 30%, rgba(0, 0, 0, 0.0) 80%);
$startpage-promo-gradient:  linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0.3) 45%,rgba(0, 0, 0, 0.5) 100%);

$map-residence-card-pagination-bg:  $off-white-two;
$map-color-house:                   $dark-sage;
$map-color-apartment:               $dull-orange;
$map-color-town-house:              $french-blue;
$map-color-plot:                    $greyish-brown;

$blur: 5px;


/*  border / outline  */
$border-radius-circle:  50%;
$border-radius-default: 4px;
$border-radius-tags:    20px;
$default-border-color:  $color-text;
$medium-border-color:   $pale;
$light-border-color:    $snow;
$active-border-color:   $accessibilityAccent;
$default-border:        1px solid $default-border-color;
$double-border:         2px solid $default-border-color;
$medium-border:         1px solid $medium-border-color;
$white-border:          1px solid $light-border-color;
$accent-border:         1px solid $active-border-color;
$accent-border-wide:    2px solid $active-border-color;
$search-field-border:   1px solid #f6f5f4;

$outline-style:             1px dashed $primaryAccent;
$table-row-bottom-border:   2px solid $sand;

$shadow-base:               0 2px 3px 0 rgba($premiumgrey, 0.2);
$shadow-highlight:          0 3px 5px 0 rgba($premiumgrey, 0.25);
$shadow-pop-out:            -1px 5px 5px 0 rgba($premiumgrey, 0.25);
$shadow-left:               -5px 0px 10px 0 rgba($premiumgrey, 0.2);
$shadow-top:                0 -1px 6px 0 rgba($premiumgrey, 0.15);
$shadow-text:               0 2px 4px rgba(0, 0, 0, 0.18);

/*  gaps, margins, padding  */
$gallery-gap:                5px;
$standard-grid-gap-mobile:  10px;
$mobile-side-margin:        20px;
$standard-grid-gap-desktop: 25px;
$block-margin-mobile:       30px;
$large-grid-gap-desktop:    40px;
$block-margin-desktop:      50px;

$menu-height-mobile:        90px;
$menu-height-mobile-map:    48px;
$menu-height-tablet:        50px;
$menu-height-desktop:       85px;
$menu-height-desktop-map:   60px;
$top-bar-logo-small:        20px; // on mobile
$top-bar-logo-medium:       31px; // on map view
$top-bar-logo-large:        55px; // default desktop size
$iphone-bottom-bar-height:  90px;

$top-image-height:              50vh;
$top-image-height-max:          570px;
$top-image-height-large:        65vh;
$top-image-height-max-large:    800px;

/*  buttons */
$button-padding-large-vert:     13px;
$button-padding-large-hor:      35px;
$button-padding-large: $button-padding-large-vert $button-padding-large-hor;
$button-padding-default-vert:    8px;
$button-padding-default-hor:    35px;
$button-padding-default: $button-padding-default-vert $button-padding-default-hor;
$button-padding-small-vert:      8px;
$button-padding-small-hor:      25px;
$button-padding-small: $button-padding-small-vert $button-padding-small-hor;
$button-default-color:      $premiumgrey;
$button-cta-color:          $brick-orange;
$button-sticky-cta-color:   $status-for-sale;
$button-height:         2.63rem;
$button-height-small:   1.88rem;

$checkbox-size:     20px;
$counter-size:      30px;

/*  transitions */
$transition-speed-default:  .3s;
$transition-speed-slow:     .8s;
$transition-default: $transition-speed-default ease-out;

/*  z-index     */
$modal:         9000;
$backdrop:      8000;
$overlay:       1000;
$block-overlay: 10;
$default:       1;
$underlay:      -1;

/*  breakpoints */
$bp-two-cards:          39.06rem; //625px; // standing iPad
$bp-three-cards:        48.5rem;//48.06rem; //769px; // desktop
$bp-content-max-width:  75.63rem;//1210px;
$content-max-wide:      2560px;
$form-max-width:        335px;

$foldout-filter-default: 420px;
$foldout-filter-wide: 1000px;

/*  residence-card  */
$residence-card-image-height-mobile: 209px;
$residence-card-image-height-desktop: 260px;