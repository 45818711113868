@font-face {
    font-family: "GillSansJM";
    src: url('/public/fonts/gil-sans-jm/GillSansforJM-Regular.eot');
    src:
        url('/public/fonts/gil-sans-jm/GillSansforJM-Regular.eot?iefix') format('embedded-opentype'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Regular.svg#GillSansforJM-Regular') format('svg'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Regular.woff') format('woff'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: "JMSerif";
    src: url('/public/fonts/jm-serif/JMSerif-Regular.eot');
    src:
        url('/public/fonts/jm-serif/JMSerif-Regular.eot?iefix') format('embedded-opentype'),
        url('/public/fonts/jm-serif/JMSerif-Regular.woff') format('woff'),
        url('/public/fonts/jm-serif/JMSerif-Regular.ttf') format('truetype'),
        url('/public/fonts/jm-serif/JMSerif-Regular.woff2') format('woff2'),
        url('/public/fonts/jm-serif/JMSerif-Regular.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: "JMSerif-medium";
    src: url('/public/fonts/jm-serif/JMSerif-Medium.eot');
    src:
        url('/public/fonts/jm-serif/JMSerif-Medium.eot?iefix') format('embedded-opentype'),
        url('/public/fonts/jm-serif/JMSerif-Medium.woff') format('woff'),
        url('/public/fonts/jm-serif/JMSerif-Medium.ttf') format('truetype'),
        url('/public/fonts/jm-serif/JMSerif-Medium.woff2') format('woff2'),
        url('/public/fonts/jm-serif/JMSerif-Medium.otf') format("opentype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;

}

/*
@font-face {
    font-family: "GillSansJM";
    src: url('/public/fonts/gil-sans-jm/GillSansforJM-Italic.eot');
    src:
        url('/public/fonts/gil-sans-jm/GillSansforJM-Italic.eot?iefix') format('embedded-opentype'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Italic.svg#GillSansforJM-Italic') format('svg'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Italic.woff') format('woff'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "GillSansJM";
    src: url('/public/fonts/gil-sans-jm/GillSansforJM-Light.eot');
    src:
        url('/public/fonts/gil-sans-jm/GillSansforJM-Light.eot?iefix') format('embedded-opentype'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Light.svg#GillSansforJM-Light') format('svg'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Light.woff') format('woff'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-Light.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;

}

@font-face {
    font-family: "GillSansJM";
    src: url('/public/fonts/gil-sans-jm/GillSansforJM-LightItalic.eot');
    src:
        url('/public/fonts/gil-sans-jm/GillSansforJM-LightItalic.eot?iefix') format('embedded-opentype'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-LightItalic.svg#GillSansforJM-LightItalic') format('svg'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-LightItalic.woff') format('woff'),
        url('/public/fonts/gil-sans-jm/GillSansforJM-LightItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;

}
*/

/*
@font-face {
    font-family: "JMSerif";
    src: url('/public/fonts/jm-serif/JMSerif-RegularItalic.eot');
    src:
        url('/public/fonts/jm-serif/JMSerif-RegularItalic.eot?iefix') format('embedded-opentype'),
        url('/public/fonts/jm-serif/JMSerif-RegularItalic.woff') format('woff'),
        url('/public/fonts/jm-serif/JMSerif-RegularItalic.ttf') format('truetype'),
        url('/public/fonts/jm-serif/JMSerif-RegularItalic.woff2') format('woff2'),
        url('/public/fonts/jm-serif/JMSerif-RegularItalic.otf') format("opentype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;

}
 */



/*

@font-face {
    font-family: "JMSerif";
    src: url('/public/fonts/jm-serif/JMSerif-MediumItalic.eot');
    src:
        url('/public/fonts/jm-serif/JMSerif-MediumItalic.eot?iefix') format('embedded-opentype'),
        url('/public/fonts/jm-serif/JMSerif-MediumItalic.woff') format('woff'),
        url('/public/fonts/jm-serif/JMSerif-MediumItalic.ttf') format('truetype'),
        url('/public/fonts/jm-serif/JMSerif-MediumItalic.woff2') format('woff2'),
        url('/public/fonts/jm-serif/JMSerif-MediumItalic.otf') format("opentype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;

}
*/
